import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FancyDropdown, {
  FancyDropdownMenu,
  FancyDropdownMenuItem
} from 'dpl/components/FancyDropdown';
import Icon from 'dpl/common/components/Icon';
import { isEmpty } from 'dpl/shared/utils/object';

export function getAvailableTagsForInputValue(availableTags, inputValue) {
  const searchTerm = inputValue.toLowerCase().trim();
  if (!searchTerm) {
    return availableTags;
  }

  return availableTags.filter(tag =>
    tag.title.toLowerCase().includes(searchTerm)
  );
}

export default class TagSelector extends Component {
  static propTypes = {
    onValueSelect: PropTypes.func.isRequired,
    onCustomValueSelect: PropTypes.func,
    onValueRemove: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    selectedValues: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    availableTags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      })
    ).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    unavailableTags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      })
    ),
    id: PropTypes.string,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    onCustomValueSelect: null,
    id: null,
    placeholder: null,
    unavailableTags: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const selectedValuesChanged =
      prevState.selectedTags.length !== nextProps.selectedValues.length;

    // this is a naïve check. there's a chance a value w/in the same length has
    // changed. we're assuming not for now bc can get expensive quickly
    const availableTagsChanged =
      prevState.availableTags.length !== nextProps.availableTags.length;

    if (!(availableTagsChanged || selectedValuesChanged)) {
      return null;
    }

    const availableTagsWithoutSelected = [];

    // initialize w/ array of temp tags. this is useful for custom tags, where
    // the tag's value is also its title. persisted (not custom) tags' titles
    // are overwritten below
    const selectedTags = nextProps.selectedValues.map(v => ({
      value: v,
      title: v
    }));

    nextProps.availableTags.forEach(tag => {
      const selectedTagIdx = nextProps.selectedValues.indexOf(tag.value);

      if (selectedTagIdx >= 0) {
        selectedTags[selectedTagIdx].title = tag.title;
      } else {
        availableTagsWithoutSelected.push(tag);
      }
    });

    // this is being added to be used with breeder puppy extras and training programs
    // which have v1 and v2 versions. We do not want v1 versions to be part of
    // available tags but still want to display them
    if (nextProps.unavailableTags) {
      nextProps.unavailableTags.forEach(tag => {
        const selectedUnavailableTagIdx = nextProps.selectedValues.indexOf(
          tag.value
        );
        if (selectedUnavailableTagIdx >= 0) {
          selectedTags[selectedUnavailableTagIdx].title = tag.title;
        }
      });
    }

    // clear input field if we just selected a new tag
    const inputValue =
      selectedTags.length > prevState.selectedTags.length
        ? ''
        : prevState.inputValue;

    const availableTagsToBeDisplayed = getAvailableTagsForInputValue(
      availableTagsWithoutSelected,
      inputValue
    );

    return {
      inputValue,
      selectedTags,
      availableTags: nextProps.availableTags,
      availableTagsWithoutSelected,
      availableTagsToBeDisplayed
    };
  }

  state = {
    isOpen: false,
    inputValue: '',
    selectedTags: [],
    // eslint-disable-next-line react/no-unused-state
    availableTags: [],
    availableTagsWithoutSelected: [],
    availableTagsToBeDisplayed: []
  };

  inputRef = createRef();

  handleValueSelect = value => e => {
    e.preventDefault();
    this.props.onValueSelect(value);
    this.handleInputBlur();
  };

  handleClick = () => {
    this.inputRef.current.focus();
    this.setState({ isOpen: true });
  };

  handleKeyDown = e => {
    if (e.key === 'Backspace' && !e.target.value) {
      const { selectedValues } = this.props;
      const lastValue = selectedValues[selectedValues.length - 1];
      lastValue && this.props.onValueRemove(lastValue);
    }
  };

  handleInputChange = e => {
    const inputValue = e.target.value;
    const { availableTagsWithoutSelected } = this.state;

    const availableTagsToBeDisplayed = getAvailableTagsForInputValue(
      availableTagsWithoutSelected,
      inputValue
    );

    const newState = {
      inputValue,
      availableTagsToBeDisplayed,
      isOpen: true
    };

    this.setState(newState);
  };

  handleInputBlur = () => {
    this.setState({ isOpen: false, inputValue: '' });
  };

  handleDropdownChange = isOpen => {
    this.setState({ isOpen });
  };

  handleCustomValueSelect = () => {
    const { onCustomValueSelect, onValueRemove, selectedValues } = this.props;

    const { inputValue } = this.state;

    if (selectedValues.includes(inputValue)) {
      onValueRemove(inputValue);
      // have to wait for state to update to reflect removal
      this.forceUpdate(() => {
        onCustomValueSelect(inputValue);
      });
      return;
    }

    onCustomValueSelect(inputValue);
  };

  render() {
    const { isOpen, inputValue, availableTagsToBeDisplayed, selectedTags } =
      this.state;

    const { onValueRemove, onCustomValueSelect, id, placeholder } = this.props;

    return (
      <div className="TagSelector" data-test-id={id}>
        <FancyDropdown isOpen={isOpen} onChange={this.handleDropdownChange}>
          <div
            role="presentation"
            onClick={this.handleClick}
            className={classnames(
              'TagSelector__tagContainer flex flex-wrap items-center ba b--light-gray br3 ph4 pv2 font-16',
              { 'br--top': isOpen }
            )}
          >
            {selectedTags.map((tag, idx) => (
              <span
                key={idx}
                className="TagSelector__tag pl2 flex items-center br-pill bg-cloud midnight"
              >
                <span className="pl4 pr2 pv1">{tag.title}</span>
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    onValueRemove(tag.value);
                  }}
                  className="TagSelector__removeButton pr4"
                  aria-label="remove"
                >
                  <Icon
                    height="12px"
                    name="close"
                    width="12px"
                    className="midnight"
                  />
                </button>
              </span>
            ))}
            <input
              type="text"
              autoComplete="none"
              ref={this.inputRef}
              className="TagSelector__input b--none flex-auto"
              onChange={this.handleInputChange}
              onBlur={this.handleInputBlur}
              onKeyDown={this.handleKeyDown}
              value={inputValue}
              id={id}
              placeholder={selectedTags.length === 0 ? placeholder : null}
            />
          </div>
          <div role="presentation" onMouseDown={e => e.preventDefault()}>
            <FancyDropdownMenu className="TagSelector__dropdownMenu overflow-y-auto ba b--light-gray br2 br--bottom break-word font-16">
              {availableTagsToBeDisplayed.map(tag => (
                <FancyDropdownMenuItem key={tag.value} itemKey={tag.value}>
                  <button
                    type="button"
                    onClick={this.handleValueSelect(tag.value)}
                    className="pv3 ph4 w-100"
                  >
                    {tag.title}
                  </button>
                </FancyDropdownMenuItem>
              ))}
              {inputValue && onCustomValueSelect && (
                <div className="mt2 pt2 bt b--light-gray">
                  <FancyDropdownMenuItem itemKey="NEW_TAG">
                    <button
                      type="button"
                      onClick={this.handleCustomValueSelect}
                      className="pv3 ph4 w-100"
                    >
                      Add &quot;{inputValue}&quot;
                    </button>
                  </FancyDropdownMenuItem>
                </div>
              )}
              {inputValue &&
                !onCustomValueSelect &&
                isEmpty(availableTagsToBeDisplayed) && (
                  <FancyDropdownMenuItem
                    itemKey="NOT_FOUND_TAG"
                    className=""
                    selectedClassName=""
                  >
                    No results for &quot;{inputValue}&quot;
                  </FancyDropdownMenuItem>
                )}
            </FancyDropdownMenu>
          </div>
        </FancyDropdown>
      </div>
    );
  }
}
