import React from 'react';
import PropTypes from 'prop-types';

import Image from 'dpl/image_cropping/components/Image.next';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import Icon from 'dpl/common/components/Icon';

const IMAGE_FAILED_MESSAGE = `Something went with your image. Please refresh page, or click continue to skip.`;

export default function ProfilePhotoQuestion({ name }) {
  const { addToFormState, formFieldValue } = useFormContext(name);

  return (
    <div className="ProfilePhotoQuestion QuestionLayout flex justify-center">
      <Image
        className="ProfilePhotoQuestion__image mb14"
        imageUploadClassName="bw2 b--dashed b--default br-100 hover-bg-cloud bg-white transition"
        photograph={formFieldValue}
        onSave={photograph => addToFormState(name, photograph)}
        imageFailedMessage={IMAGE_FAILED_MESSAGE}
        inputName={name}
        openCropOverlayOnUpload
      >
        <div className="transform-center-all max-content pv1 ph1 br-100 bg-cloud">
          <Icon
            height="24x"
            width="24px"
            name="fetch-plus"
            className="midnight"
          />
        </div>
      </Image>
    </div>
  );
}

ProfilePhotoQuestion.propTypes = {
  name: PropTypes.string.isRequired
};
