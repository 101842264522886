import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import InputCard from 'dpl/common/design_system/InputCard';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_LG = isLessThanBreakpoint('lg');

export default function WideInputCardQuestion({ name, options, multiple }) {
  const { handleFormFieldChange, addToFormState, formFieldValue } =
    useFormContext(name);

  function isOptionSelected(opt) {
    if (multiple) {
      return (formFieldValue ?? []).includes(opt.value);
    }

    return opt.value === formFieldValue;
  }

  function handleChange(e) {
    if (multiple) {
      const { value } = e.target;

      const currentValues = [...(formFieldValue ?? [])];

      if (currentValues.includes(value)) {
        currentValues.splice(currentValues.indexOf(value), 1);
      } else {
        currentValues.push(value);
      }
      addToFormState(name, currentValues);
    } else {
      handleFormFieldChange(e);
    }
  }

  const inputType = multiple ? 'checkbox' : 'radio';

  return (
    <div className="WideInputCardQuestion QuestionLayout">
      <ul className="justify-center tl">
        {options.map(option => (
          <li
            className="flex flex-column items-center"
            key={option.value}
            data-test-id={option.value}
          >
            <InputCard
              className={classnames(
                'bg-white ba mh1 mv1 ph4 pv4 flex inline-flex',
                isOptionSelected(option) ? 'b--midnight' : 'b--default'
              )}
              icon={{
                name: option.icon,
                size: '20px',
                className: isOptionSelected(option)
                  ? 'bg-cloud midnight ph1 pv1 mr4'
                  : 'bg-passive tertiary ph1 pv1 mr4'
              }}
              type={inputType}
              minHeight={IS_LT_LG ? '94px' : '74px'}
              width={IS_LT_LG ? '335px' : '558px'}
              inputProps={{
                name,
                value: option.value,
                checked: isOptionSelected(option),
                onChange: handleChange
              }}
              variant="wide"
            >
              <h6 className="f3">{option.title}</h6>
              <p className="secondary font-14">{option.description}</p>
            </InputCard>
          </li>
        ))}
      </ul>
    </div>
  );
}

WideInputCardQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired,
  multiple: PropTypes.bool
};

WideInputCardQuestion.defaultProps = {
  multiple: false
};
