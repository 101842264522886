import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import InputCard from 'dpl/common/design_system/InputCard';

export default function InputCardQuestion({ name, options, multiple }) {
  const { handleFormFieldChange, addToFormState, formFieldValue } =
    useFormContext(name);

  function isOptionSelected(opt) {
    if (multiple) {
      return (formFieldValue ?? []).includes(opt.value);
    }

    return opt.value === formFieldValue;
  }

  function handleChange(e) {
    if (multiple) {
      const { value } = e.target;

      const currentValues = [...(formFieldValue ?? [])];

      if (currentValues.includes(value)) {
        currentValues.splice(currentValues.indexOf(value), 1);
      } else {
        currentValues.push(value);
      }
      addToFormState(name, currentValues);
    } else {
      handleFormFieldChange(e);
    }
  }

  const inputType = multiple ? 'checkbox' : 'radio';

  return (
    <div className="InputCardQuestion QuestionLayout">
      <ul className="flex flex-wrap justify-center tl">
        {options.map(option => (
          <li key={option.value} data-test-id={option.value}>
            <InputCard
              className={classnames(
                'bg-white tertiary ba mh1 mv1 ph4 pv4',
                isOptionSelected(option) ? 'b--midnight midnight' : 'b--default'
              )}
              icon={{
                name: option.icon,
                size: '20px',
                className: isOptionSelected(option)
                  ? 'bg-cloud midnight ph1 pv1 mb6'
                  : 'bg-passive tertiary ph1 pv1 mb6'
              }}
              type={inputType}
              minHeight="110px"
              width="163px"
              inputProps={{
                name,
                value: option.value,
                checked: isOptionSelected(option),
                onChange: handleChange
              }}
            >
              <span className="font-16">{option.label}</span>
            </InputCard>
          </li>
        ))}
      </ul>
    </div>
  );
}

InputCardQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired,
  multiple: PropTypes.bool
};

InputCardQuestion.defaultProps = {
  multiple: false
};
