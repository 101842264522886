import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import InputCard from 'dpl/common/design_system/InputCard';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_LG = isLessThanBreakpoint('lg');

export default function BreedSelectorQuestion({ name, options }) {
  const { handleFormFieldChange, formFieldValue } = useFormContext(name);

  function isOptionSelected(opt) {
    return opt.value === formFieldValue;
  }

  function handleChange(e) {
    handleFormFieldChange(e);
  }

  return (
    <div className="BreedSelectorQuestion QuestionLayout">
      <ul>
        {options.map(option => (
          <li
            className="flex flex-column items-center"
            key={option.value}
            data-test-id={option.value}
          >
            <InputCard
              className={classnames(
                'bg-white ba mh1 mv1 ph2 pv2 flex inline-flex items-center',
                isOptionSelected(option) ? 'b--midnight' : 'b--default'
              )}
              avatar={{
                cover_photo_url: option.cover_photo_url
              }}
              type="radio"
              width={IS_LT_LG ? '335px' : '320px'}
              inputProps={{
                name,
                value: option.value,
                checked: isOptionSelected(option),
                onChange: handleChange
              }}
              variant="wide"
            >
              <p className="fw-medium font-16">{option.label}</p>
            </InputCard>
          </li>
        ))}
      </ul>
    </div>
  );
}

BreedSelectorQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      cover_photo_url: PropTypes.string
    })
  ).isRequired
};
